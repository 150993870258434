import React, { Component } from "react"
import { Grid } from "semantic-ui-react"
import styled from "styled-components"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em !important;
  }

  @media only screen and (max-width: 1119px) {
    padding: 2em 1em !important;
  }
`

class TextContainer extends Component {
  render() {
    return (
      <GridRow>
        <Grid.Column largeScreen={this.props.columns}>
          {this.props.children}
        </Grid.Column>
      </GridRow>
    )
  }
}

export default TextContainer
